.container {
  display: flex;
  flex-direction: column;
}

.select-calc {
  display: flex;
  justify-content: center;
}

.select-calc ul{
  display: flex;
  list-style: none;
  gap: 30px;
}

.calculator-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

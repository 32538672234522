.activity-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.activity-level {
  display: flex;
  justify-content: center;
}

.activity-level ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.activity-level ul li {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}